export default class Newsletter {
  constructor(el) {
    this.el = el;

    this.addListeners();
  }

  addListeners() {
    const embed = document.getElementById("mc_embed_signup");

    this.el.addEventListener("click", function (event) {
      event.preventDefault();
      embed.classList.toggle("open");
      if (embed.classList.contains("open")) {
        document.getElementById("mce-FNAME").focus();
      }
    });

    window.addEventListener("popstate", function (event) {
      if (window.location.href.endsWith("#CSS")) {
        embed.classList.add("open");
      }
    });

    if (window.location.href.endsWith("#CSS")) {
      embed.classList.add("open");
    }
  }
}
